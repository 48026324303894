import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Link } from 'gatsby'
import QuoteComponent from '../../components/QuoteComponent'

const MountlyticsPage = () => (
  <Layout>
    <SEO
      title="MountLytics"
      description="Read the case study of MountLytics, a smart CRM for Hotels."
    />
    <section>
      <hgroup>
        <h6>2016-2019</h6>
        <h2>MountLytics</h2>
      </hgroup>
      <div className="project-header-image wide">
        <Image filename="mountlytics-header" />
      </div>
      <div className="section-columns">
        <div>
          <h3>Overview</h3>
          <p>
            I started to work on the very first version of MountLytics in 2016
            as Freelancer. In October 2017 I joined the team as only UX/UI
            designer and started to work on the new version. MountLytics is a
            smart CRM system for hotels, which collects guest data from all
            existing data silos in a hotel and accumulates them in a guest
            profile. MountLytics helps hotels to send personalized and automated
            communication before, during and after the stay.
          </p>
          <p>
            Today most hotels are using lot’s of different applications to run
            their business. Most of the tools don’t use the PMS data with real
            guest data, so hotels can’t use them for communication or they have
            to upload them manually. Some guest data even get’s lost, as
            employees write them down and don’t add them to the PMS.
          </p>
          <p>
            Personalized communication and real segmentation based on data is
            not possible. Also there is no single tool for frontdesk employees,
            marketing managers and the general managers. To meet the needs of
            the different user roles, we needed to come up with a solution that
            can work for each role, while being flexible, user-friendly and
            accessible in many different scenarios.
          </p>
        </div>
        <div className="pt-48">
          <p>
            During the design process, I came across many different obstacles,
            as the application is very complex and each role has different
            views. In my opinion, one key factor that contributed to the success
            of the many iterations, was the component based approach of the
            interface. I also started to create a design system from the
            beginning, so I could do changes and iterations in screens quickly
            to test new scenarios and features. The latest version of the
            application consists of more than 100 Screens, build in Sketch.
          </p>
          <p>
            Moreover I continiously gathered feedback from all stakeholders and
            customers during the iterations. This open and continuous feedback
            loop was a crucial part during the design phase.
          </p>
        </div>
        <div>
          <h3>Challenge</h3>
          <p>
            The hotel software market uses lot’s of isolated software solutions,
            which don’t fit the need of todays guest communication and are way
            too complicated to setup and maintain. We wanted to build a generic
            base with modular, extendable functionality while always keeping a
            user-focused approach. The new system should support the user
            proactively.
          </p>
          <p>
            Furthermore, the different users are generally under immense stress
            and have to work quickly to meet the guest expectations and create
            great guest experiences. Tasks like checkin, adding guest
            information, sending tips and information for the stay,
            communicating with the guest, managing upsellings and overlooking
            statistics, have to be done as effective as possible and I needed to
            optimize the workflows.
          </p>
          <p>
            Dealing with different data sets from different PMS systems and
            custom hotel setups also needed a solution for our modular system as
            we wanted to provide the same flexibility for each hotel regardless
            of the data models.
          </p>
        </div>
        <div>
          <QuoteComponent
            brand="mountlytics"
            author="- Daniel Litschel, Product Manager"
            quotetext="Product Managers and UX Designers often have to discuss important business decisions. It is way more than looking at buttons, positions and colors. It's really about understanding the business, creating the product and still have an eye on details is really difficult to manage. Danny helps you to manage this and make it seem easy and fun. A real inspiration! He has all the skills to create awesome products and make them special."
          />
        </div>
        <div>
          <Image filename="mountlytics-scribbles" />
        </div>
        <div>
          <h3>Approach</h3>
          <p>
            To solve all the challenges successfully, I’ve built many different
            prototypes from scribbles, wireframes to high-fidelity mockups and
            kept a close communication with our pilot hotels and also other
            stakesholders, like customer success, sales and development. We
            created a product roadmap and wrote different release definitions,
            so we were able to start with an MVP and add more features over
            time. As the application consists of many complex features, I also
            created flowcharts for each section.
          </p>
          <p>
            The prototypes were mostly static click dummies that featured
            different ideas to test them fast and easily with different users.
            To speed up the process I continously developed the design system
            and created a fully modular system based on Sketch and Abstract,
            which works with reusable and customizable symbols across files to
            easily create different ideas. The system also helped the
            development team to implement new features faster.
          </p>
        </div>
        <div>
          <h3>Implementation</h3>
          <p>
            In the product development team, I was responsible for the whole
            concept and ux/ui design process, product vision, feature definition
            as well as managing a small team of a brand designers, design and
            marketing students and marketing managers. I was also in charge of
            delivering assets for the developers and supporting in front-end
            development in Angular and React. Besides the product development I
            maintained the website, created mail templates and also designed
            investor pitch presentations, sales sheets and more.
          </p>
        </div>
        <div className="pt-48">
          <p>
            There were two main focus areas in the application: the guest
            database and the creation of mail automations. Marketing Managers
            needed to have an easy way of creating segments and building new
            mails for automations. They also needed a system, which helps them
            proactively and suggests next actions.
          </p>
        </div>
      </div>

      <div className="section-columns col-8-4">
        <div>
          <Image filename="mountlytics-guestprofile" />
        </div>
        <div>
          <p>
            Guest profiles offered the basic guest information and also helped
            the frontdesk employees to add additional information like notes,
            interests, likes and dislikes to accumulate the profile for better
            personalized communication.
          </p>
        </div>
      </div>
      <div className="section-columns col-4-8">
        <div>
          <p>
            Based on guest profile information, hotels were able to create
            segments to communicate differently with each segment. Segments
            could be created from general profile information and stays, but
            also from interests, likes or dislikes.
          </p>
          <p>
            Also MountLytics suggested new segments based on the updating data.
          </p>
        </div>
        <div>
          <Image filename="mountlytics-segments" />
        </div>
      </div>
      <div className="section-columns col-8-4">
        <div>
          <Image filename="mountlytics-data" />
        </div>
        <div>
          <p>
            Backend application for customer management. Because of the
            different data sets from the PMS systems, we had to create an easy
            data mapping tool to map any PMS data to our data model.
          </p>
        </div>
      </div>

      <div className="section-columns col-4-8">
        <div>
          <p>
            To support the creation process of new mails, I implemented content
            suggestions, as our customers needed some inspiration. The
            suggestions were splitted into automation mails and campaign mails.
            Automation mail suggestions were also created along the guest
            journey.
          </p>
          <p>
            Depending on the selected segment one step earlier and the already
            created mails, suggestions change.
          </p>
        </div>
        <div>
          <Image filename="mountlytics-automations" />
        </div>
      </div>

      <div className="section-columns col-8-4">
        <div>
          <Image filename="mountlytics-inbox" />
        </div>
        <div>
          <p>
            We also implemented an inbox in case guests will reply to received
            mails. The inbox is an easy way for the guest experience manager, to
            reply quickly and also have access to the guest profiles.
          </p>

          <p>
            Requests for upsellings also support adding upselling content from
            managed upselling offers in MountLytics.
          </p>
        </div>
      </div>
    </section>
    <div className="cta-section">
      <section>
        <h2>Do you have a product vision?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to realise their
          vision in a market ready product. <br /> I would be happy to help you,
          developing your digital product from scratch and get it live.
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Let's talk
        </a>
      </section>
    </div>
    <section>
      <div className="similar">
        <div>
          <Link to="/project/triphunt/">Read Triphunt study</Link>
        </div>
        <div className="text-center">
          <Link to="/">Go to homepage</Link>
        </div>
        <div className="text-right">
          <Link to="/project/kapilendo/">Read Kapilendo study</Link>
        </div>
      </div>
    </section>
  </Layout>
)
export default MountlyticsPage
